import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  VStack,
  Text,
  Button,
  Badge,
  Wrap,
  WrapItem,
  Tag,
  FormControl,
  Input,
  Stat,
  StatLabel,
  StatNumber,
  Icon,
  Stack,
  Flex,
} from "@chakra-ui/react";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../../../Firebase";
import { useNavigate, useOutletContext } from "react-router-dom";
import { FaClipboardList } from "react-icons/fa";
import DateRangeSelector from "../../utils/DateRangeSelector";
import AllReportsPDF from "./AllReportsPDF";

const Reports = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const { userDoc, orgData } = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReports = async () => {
      if (userDoc?.orgId) {
        setLoading(true);
        try {
          const reportsRef = collection(db, "org", userDoc.orgId, "reports");
          const q = query(reportsRef, orderBy("timeSubmitted", "desc"));

          const querySnapshot = await getDocs(q);
          const fetchedReports = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const reportsWithStats = fetchedReports.map((report) => ({
            ...report,
            stats: calculateReportStats(report),
          }));

          setReports(reportsWithStats);
        } catch (error) {
          console.error("Error fetching reports:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchReports();
  }, [userDoc]);

  const calculateReportStats = (report) => {
    let totalPoints = 0;
    let earnedPoints = 0;
    let totalPassFail = 0;
    let passedCount = 0;

    report.formResponses.forEach((response) => {
      if (response.answerDetails) {
        if (typeof response.answerDetails === "object") {
          if ("points" in response.answerDetails) {
            totalPoints += response.answerDetails.maxPoints || 0;
            earnedPoints += response.answerDetails.points || 0;
          }
          if ("result" in response.answerDetails) {
            totalPassFail++;
            if (response.answerDetails.result === "pass") {
              passedCount++;
            }
          }
        }
      }
    });

    const pointsPercentage =
      totalPoints > 0 ? (earnedPoints / totalPoints) * 100 : 100;
    const allPassFailPassed = totalPassFail === passedCount;
    const overallPass = allPassFailPassed && pointsPercentage >= 70;

    return {
      totalPoints,
      earnedPoints,
      totalPassFail,
      passedCount,
      overallPass,
    };
  };

  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const formatDate = (date) => {
    return date
      ? new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      : "";
  };

  const handleViewDetails = (reportId) => {
    navigate(`/dashboard/reports/${reportId}`);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTagClick = (tag) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag)
        ? prevTags.filter((t) => t !== tag)
        : [...prevTags, tag]
    );
  };

  // Find the staff member with matching UID
  const currentUserStaffData = orgData?.staff?.find(
    (staff) => staff.uid === userDoc.uid
  );

  // Get the "See All Reports" permission value for the current user
  const seeAllReportsPermission =
    currentUserStaffData?.permissions?.["See All Reports"] || false;

  // Filter reports based on permissions and filters
  const filteredReports = reports.filter((report) => {
    // Permission check
    if (!seeAllReportsPermission) {
      // Only show the report if submittedBy.id or submittedFor.id matches userDoc.uid
      if (
        report.submittedBy?.id !== userDoc.uid &&
        report.submittedFor?.id !== userDoc.uid
      ) {
        return false;
      }
    }

    // Date range filter
    const reportDate = new Date(report.timeSubmitted);
    const effectiveEndDate = endDate
      ? new Date(new Date(endDate).setHours(23, 59, 59, 999))
      : null;

    const isWithinDateRange =
      (!startDate || reportDate >= new Date(startDate)) &&
      (!effectiveEndDate || reportDate <= effectiveEndDate);

    // Search term filter
    const matchesSearchTerm =
      report.evaluationName
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      (report.evaluationTags &&
        report.evaluationTags.some((tag) =>
          tag.toLowerCase().includes(searchTerm.toLowerCase())
        ));

    // Tag filter
    const matchesSelectedTags =
      selectedTags.length === 0 ||
      (report.evaluationTags &&
        selectedTags.every((tag) => report.evaluationTags.includes(tag)));

    return isWithinDateRange && matchesSearchTerm && matchesSelectedTags;
  });

  const uniqueTags = Array.from(
    new Set(reports.flatMap((report) => report.evaluationTags || []))
  );

  return (
    <Box>
      {/* Responsive layout for filters and actions */}
      <Stack
        mb={4}
        spacing={4}
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "stretch", md: "center" }}
      >
        <FormControl>
          <Input
            id="search"
            name="search"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search evaluations or tags"
            w="full"
          />
        </FormControl>
        <DateRangeSelector onDateRangeChange={handleDateRangeChange} />
        <AllReportsPDF reports={filteredReports} formatDate={formatDate} />
      </Stack>

      {/* Wrap for tags */}
      <Wrap spacing={2} mb={4}>
        {uniqueTags.map((tag) => (
          <WrapItem key={tag}>
            <Button
              size="sm"
              onClick={() => handleTagClick(tag)}
              colorScheme={selectedTags.includes(tag) ? "blue" : "gray"}
            >
              {tag}
            </Button>
          </WrapItem>
        ))}
      </Wrap>

      {loading ? (
        <Text>Loading reports...</Text>
      ) : filteredReports.length === 0 ? (
        <Text>
          No reports found
          {startDate && endDate ? " for the selected date range" : ""}.
        </Text>
      ) : (
        <VStack spacing={4} align="stretch">
          {filteredReports.map((report) => (
            <Box key={report.id}>
              <Box py={2} px={4} borderWidth="1px" borderRadius="md">
                <Flex
                  direction={{ base: "column", md: "row" }}
                  alignItems={{ base: "flex-start", md: "center" }}
                  justifyContent="space-between"
                >
                  {/* Left Side: Content */}
                  <Box flex="1" mr={{ base: 0, md: 1 }}>
                    {/* Header Section */}
                    <Flex
                      direction={{ base: "column", md: "row" }}
                      alignItems={{ base: "flex-start", md: "center" }}
                      mb={4}
                    >
                      <Icon
                        as={FaClipboardList}
                        boxSize={6}
                        color="blue.500"
                        mr={{ base: 0, md: 4 }}
                        mb={{ base: 2, md: 0 }}
                      />
                      <Box mr={{ base: 0, md: 4 }}>
                        <Heading size="md">{report.evaluationName}</Heading>
                        <Text fontSize="sm" color="gray.500">
                          {formatDate(report.timeSubmitted)}
                        </Text>
                      </Box>
                      <Badge
                        colorScheme={report.stats.overallPass ? "green" : "red"}
                        mr={{ base: 0, md: 2 }}
                        mb={{ base: 2, md: 0 }}
                      >
                        {report.stats.overallPass ? "PASS" : "FAIL"}
                      </Badge>
                      <Badge
                        colorScheme={
                          report.status === "verified" ? "green" : "orange"
                        }
                        mb={{ base: 2, md: 0 }}
                      >
                        {report.status || "Pending"}
                      </Badge>
                    </Flex>

                    {/* Information and Statistics Sections */}
                    <Flex
                      direction={{ base: "column", md: "row" }}
                      alignItems={{ base: "flex-start", md: "center" }}
                    >
                      <Box mr={{ base: 0, md: 4 }} mb={{ base: 2, md: 0 }}>
                        <Text fontSize="sm">Submitted By</Text>
                        <Text fontWeight="bold">
                          {report.submittedBy.name}
                        </Text>
                      </Box>
                      <Box mr={{ base: 0, md: 4 }} mb={{ base: 2, md: 0 }}>
                        <Text fontSize="sm">Evaluated Person</Text>
                        <Text fontWeight="bold">
                          {report.submittedFor.name}
                        </Text>
                      </Box>
                      <Box mr={{ base: 0, md: 4 }} mb={{ base: 2, md: 0 }}>
                        <Stat>
                          <StatLabel>Points Earned</StatLabel>
                          <StatNumber>
                            {report.stats.earnedPoints}/
                            {report.stats.totalPoints}
                          </StatNumber>
                        </Stat>
                      </Box>
                      <Box mr={{ base: 0, md: 4 }} mb={{ base: 2, md: 0 }}>
                        <Stat>
                          <StatLabel>Pass/Fail</StatLabel>
                          <StatNumber>
                            {report.stats.passedCount}/
                            {report.stats.totalPassFail}
                          </StatNumber>
                        </Stat>
                      </Box>
                    </Flex>

                    {/* Tags Section */}
                    {report.evaluationTags && (
                      <Wrap spacing={1}>
                        {report.evaluationTags.map((tag, index) => (
                          <WrapItem key={index}>
                            <Tag size="sm" colorScheme="teal">
                              {tag}
                            </Tag>
                          </WrapItem>
                        ))}
                      </Wrap>
                    )}
                  </Box>

                  {/* Right Side: Action Button */}
                  <Box mt={{ base: 4, md: 0 }}>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      onClick={() => handleViewDetails(report.id)}
                    >
                      View Details
                    </Button>
                  </Box>
                </Flex>
              </Box>
            </Box>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default Reports;
